import { LitElement, html, css, unsafeCSS } from 'lit-element';
import { STYLES } from '../autogen-css/styles';
import './dropdown-pagination';
import './error-pets';
import './last-pet-card';
import './loading-pets';
import './multi-select';
import './no-available-pets';
import './no-matching-pets';
import './pet-card';
import { Client } from '@petfinder/petfinder-js';
import _debounce from 'lodash/debounce';
import { ANIMAL_TYPES_ENUM } from '../../../../core/scripts/types/animal-types';
import { focusFirstFocusable } from '../../../../core/scripts/lib/focusManager/index';

/**
 * The list of known filters
 * @type {Array}
 */
const FILTERS = ['type', 'breed', 'size', 'gender', 'age', 'goodWith', 'care'];

/**
 * The list of simulated breakpoints compared against the window width and the
 * corresponding class to assign to the filters container
 * @type {Array}
 */
const BREAKPOINTS = [
    {
        width: 580,
        name: 'Md',
    },
    {
        width: 860,
        name: 'Lg',
        mobileMaximumWidth: true,
    },
    {
        width: 1100,
        name: 'Xl',
    },
];

/**
 * The class applied to the filters container to display the rest of the filters
 * @type {String}
 */
const ALL_FILTERS_SHOWING_CLASS = 's-showMore';

/**
 * The class applied to the results container to indicate results are re-rendering
 * @type {String}
 */
const LOADING_CLASS = 's-loading';

/**
 * Container component for the embeddable pet list
 * @class PetScroller
 * @extends LitElement
 */
class PetScroller extends LitElement {
    static styles = css`
        ${unsafeCSS(STYLES)}
    `;

    static get properties() {
        return {
            // Public
            // --------
            accessToken: { type: String },
            apiBase: { type: String },
            petfinderUrl: { type: String },
            containerWidth: { type: Number },

            // Optional locked values from config page
            type: { type: Array },
            breed: { type: Array },
            size: { type: Array },
            gender: { type: Array },
            age: { type: Array },
            goodWith: { type: Array },
            care: { type: Array },
            limit: { type: Number },
            hideBreed: { type: Boolean },
            petListTitle: { type: String },

            // Extra hidden options
            organization: { type: String },
            status: { type: String },

            // Internal
            // --------
            _client: { type: Object },
            _animals: { type: Array },
            _currentPage: { type: Number },
            _totalPages: { type: Number },
            _allFiltersShowing: { type: Boolean },
            _loading: { type: Boolean },
            _error: { type: Boolean },
            _firstPetCardHeight: { type: Number },

            // Filter options
            _typeItems: { type: Array },
            _breedItems: { type: Array },
            _sizeItems: { type: Array },
            _genderItems: { type: Array },
            _ageItems: { type: Array },
            _goodWithItems: { type: Array },
            _careItems: { type: Array },

            // User applied filters
            _typeApplied: { type: Array },
            _breedApplied: { type: Array },
            _sizeApplied: { type: Array },
            _genderApplied: { type: Array },
            _ageApplied: { type: Array },
            _goodWithApplied: { type: Array },
            _careApplied: { type: Array },
        };
    }

    constructor() {
        super();

        this.accessToken = '';
        this.apiBase = '';
        this.petfinderUrl = '';
        this.containerWidth = 0;

        this.type = [];
        this.breed = [];
        this.size = [];
        this.gender = [];
        this.age = [];
        this.goodWith = [];
        this.care = [];
        this.limit = 24;
        this.hideBreed = false;
        this.petListTitle = '';

        this.organization = '';
        this.status = '';

        this._client = {};
        this._rootElement = {};
        this._animals = [];
        this._currentPage = 1;
        this._totalPages = 1;
        this._allFiltersShowing = false;
        this._loading = true;
        this._error = false;
        this._firstPetCardHeight = 0;

        this._typeItems = [];
        this._breedItems = [];
        this._sizeItems = [];
        this._genderItems = [];
        this._ageItems = [];
        this._goodWithItems = [];
        this._careItems = [];

        this._typeApplied = [];
        this._breedApplied = [];
        this._sizeApplied = [];
        this._genderApplied = [];
        this._ageApplied = [];
        this._goodWithApplied = [];
        this._careApplied = [];

        this._lastPage = false;

        // cached reference to parent container
        this._containerElement = document.querySelector('pet-scroller');

        // Resize this component when the window resizes
        document.body.onresize = _debounce(
            this.windowResizeHandler.bind(this),
            100
        );

        setTimeout(() => {
            this.windowResizeHandler();
        }, 0);
    }

    windowResizeHandler() {
        this._containerElement.setAttribute(
            'containerwidth',
            this._containerElement.getBoundingClientRect().width
        );
    }

    /**
     * Returns the currently selected or preconfigured Animal Type
     * @returns {String}
     */
    get currentAnimalType() {
        return this.type.length > 0 ? this.type[0] : this._typeApplied[0];
    }

    /**
     * Returns search parameters for use with the public API.
     * Takes either the locked value or the user selected one.
     * @returns {Object}
     */
    // TODO: We should refactor this so we don't have to strip values after
    get formattedFilters() {
        const formattedFilters = {
            type: this.currentAnimalType,
            breed:
                this.breed.length > 0
                    ? this.breed.join(',')
                    : this._breedApplied.join(','),
            size:
                this.size.length > 0
                    ? this.size.join(',')
                    : this._sizeApplied.join(','),
            gender:
                this.gender.length > 0
                    ? this.gender.join(',')
                    : this._genderApplied.join(','),
            age:
                this.age.length > 0
                    ? this.age.join(',')
                    : this._ageApplied.join(','),
            good_with_children:
                this.goodWith.length > 0
                    ? this.goodWith.includes('children')
                    : this._goodWithApplied.includes('children'),
            good_with_dogs:
                this.goodWith.length > 0
                    ? this.goodWith.includes('dogs')
                    : this._goodWithApplied.includes('dogs'),
            good_with_cats:
                this.goodWith.length > 0
                    ? this.goodWith.includes('cats')
                    : this._goodWithApplied.includes('cats'),
            declawed:
                this.care.length > 0
                    ? this.care.includes('declawed')
                    : this._careApplied.includes('declawed'),
            house_trained:
                this.care.length > 0
                    ? this.care.includes('house_trained')
                    : this._careApplied.includes('house_trained'),
            special_needs:
                this.care.length > 0
                    ? this.care.includes('special_needs')
                    : this._careApplied.includes('special_needs'),
            organization: this.organization,
            status: this.status,
            page: this._currentPage,
            limit: this.limit,
        };

        // Strip out falsy values
        // TODO: Refactor away from this
        Object.keys(formattedFilters).forEach(key => {
            const value = formattedFilters[key];
            if (value) {
                return;
            }
            delete formattedFilters[key];
        });

        return formattedFilters;
    }

    /**
     * Returns a loading class if the user paginates or re-filters the results
     * @returns {String}
     */
    get loadingClass() {
        return this._loading && this._animals.length > 0 ? LOADING_CLASS : '';
    }

    /**
     * Holds the logic for whether each filter should be hidden
     * @param  {String} filter
     * @returns {Boolean}
     */
    isFilterHidden(filter) {
        switch (filter) {
            case 'type':
                return this.type.length > 0;
            case 'breed':
                return (
                    this.breed.length > 0 ||
                    (this.type.length === 0 && this._typeApplied.length === 0)
                );
            case 'size':
                return this.size.length > 0;
            case 'gender':
                return this.gender.length > 0;
            case 'age':
                return this.age.length > 0;
            case 'goodWith':
                return this.goodWith.length > 0;
            case 'care':
                return this.care.length > 0;
            default:
                return false;
        }
    }

    /**
     * Returns the number of currently visible filters
     * @returns {Number}
     */
    get visibleFilterCount() {
        return FILTERS.filter(filter => {
            return !this.isFilterHidden(filter);
        }).length;
    }

    /**
     * Handles any selections in the filter subcomponents
     * @param  {Object} event
     */
    filterChangeHandler(event) {
        this[this.getAppliedFilterProperty(event.detail.identifier)] =
            event.detail.values;

        // Reset the pagination
        this._currentPage = 1;

        if (event.detail.identifier === 'type') {
            // Reset any filters that depend on Animal Type
            this._breedApplied = [];
            this._careApplied = [];

            if (event.detail.values.length > 0) {
                // Refresh the Breeds dropdown when a new Animal Type is chosen
                this.getAnimalBreeds(event.detail.values[0]);
            }
        }

        this.getAnimals();
    }

    /**
     * Handles the removal of a single filter
     * @param  {string} filter
     * @param  {string} value
     */
    removeFilter(filter, value) {
        this[this.getAppliedFilterProperty(filter)] = this[
            this.getAppliedFilterProperty(filter)
        ].filter(v => v !== value);

        this.getAnimals();
    }

    /**
     * Handles clicking Clear All
     * @param  {Object} event
     */
    clearAllClickHandler(event) {
        FILTERS.forEach(filter => {
            this[this.getAppliedFilterProperty(filter)] = [];
        });

        this.getAnimals();
    }

    /**
     * Handles interactions with pagination
     * @param  {Object} event
     */
    pageChangeHandler(event) {
        this._currentPage = event.detail.page;

        // call focusFirstFocusable() after pet cards have rendered
        this.getAnimals(true);
    }

    /**
     * Handles the first pet card having finished rendering. This is used
     * to get an appropriate pet card height on page load to set the
     * last pet card height.
     * @param  {Object} event
     */
    petCardRenderHandler(event) {
        this._firstPetCardHeight = event.detail.height;
    }

    /**
     * Retrieves a filtered list of animals
     */
    getAnimals(focusFirst = false) {
        this._error = false;
        this._loading = true;

        this._client.animal
            .search(this.formattedFilters)
            .then(response => {
                if (response.data.animals) {
                    this._animals = response.data.animals;
                    this._currentPage = response.data.pagination.current_page;
                    this._totalPages = response.data.pagination.total_pages;
                    this._lastPage = this._currentPage === this._totalPages;

                    if (focusFirst) {
                        // reset focus / scroll to the top on pagination change
                        focusFirstFocusable(this._rootElement);
                    }
                }
            })
            .catch(error => {
                this._error = true;
            })
            .finally(() => {
                this._loading = false;
            });
    }

    /**
     * Retrieves all breed information for a given type
     * @param  {string} type
     */
    getAnimalBreeds(type) {
        this._client.animalData
            .breeds(type)
            .then(response => {
                this._breedItems = response.data.breeds.map(breed => ({
                    label: breed.name,
                    value: breed.name.toLowerCase(),
                }));
                this.setDefaults(this._breedItems, this.breed);
            })
            .catch(error => {
                // Network error handling should be covered by getAnimals()
            });
    }

    /**
     * Populates the other filters that do not require dynamic data
     */
    getStaticFilters() {
        // In animal-types.js, "Any" is an enumerated value, but we don't need
        // to send a value for the "type" search parameter if we want to return
        // animals of any type. Thus we take it out of the selectable values,
        // and make sure Animal Type is an optional filter.
        this._typeItems = Object.keys(ANIMAL_TYPES_ENUM)
            .filter(key => key !== 'any')
            .map(key => ({
                label: ANIMAL_TYPES_ENUM[key],
                value: key,
            }));

        this.setDefaults(this._typeItems, this.type);

        this._ageItems = [
            { label: 'Baby', value: 'baby' },
            { label: 'Young', value: 'young' },
            { label: 'Adult', value: 'adult' },
            { label: 'Senior', value: 'senior' },
        ];
        this.setDefaults(this._ageItems, this.age);

        this._genderItems = [
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
        ];
        this.setDefaults(this._genderItems, this.gender);

        this._sizeItems = [
            { label: 'Small', value: 'small' },
            { label: 'Medium', value: 'medium' },
            { label: 'Large', value: 'large' },
            { label: 'XL', value: 'xlarge' },
        ];
        this.setDefaults(this._sizeItems, this.size);

        this._goodWithItems = [
            { label: 'Kids', value: 'children' },
            { label: 'Dogs', value: 'dogs' },
            { label: 'Cats', value: 'cats' },
        ];
        this.setDefaults(this._goodWithItems, this.goodWith);

        this._careItems = [
            { label: 'Declawed', value: 'declawed', animal_type: 'cat' },
            { label: 'House-trained', value: 'house_trained' },
            { label: 'Special Needs', value: 'special_needs' },
        ];
        this.setDefaults(this._careItems, this.care);
    }

    /**
     * Used to apply the public property value to the specified filter
     * @param  {Array} items
     * @param  {Array} selected
     */
    setDefaults(items, selected) {
        items.forEach(item => {
            item.selected = selected.includes(item.value);
        });
    }

    /**
     * Returns the name of the property storing the current selection(s) for a given filter
     * @param  {string} name
     */
    getAppliedFilterProperty(name) {
        return '_' + name + 'Applied';
    }

    /**
     * Returns all filter options that have been selected
     * @returns {Array}
     */
    get appliedFilters() {
        let filters = [];

        FILTERS.forEach(name => {
            const items = this['_' + name + 'Items'];
            this[this.getAppliedFilterProperty(name)].forEach(value => {
                const appliedItem = items.find(item => item.value === value);
                const label = appliedItem ? appliedItem.label : '';
                filters.push({ name: name, value: value, label: label });
            });
        });

        return filters;
    }

    /**
     * Returns all breakpoint classes less than the current window width
     * @returns {String}
     */
    get containerQueryClasses() {
        const classes = BREAKPOINTS.reduce((accumulator, currentValue) => {
            if (this.containerWidth >= currentValue.width) {
                accumulator.push(`cq-min${currentValue.name}`);
            }
            if (this.containerWidth < currentValue.width) {
                accumulator.push(`cq-max${currentValue.name}`);
            }
            return accumulator;
        }, []);
        return classes.join(' ');
    }

    /**
     * Returns whether the component's width is small enough to be considered "mobile"
     * vs. "desktop".
     * @returns {Boolean}
     */
    get isMobileView() {
        const desktopWidth =
            BREAKPOINTS.find(breakpoint => {
                return breakpoint.mobileMaximumWidth;
            }).width || 0;

        return this.containerWidth < desktopWidth;
    }

    /**
     * Returns the "all filters showing" class if the button has been clicked
     * @returns {String}
     */
    get filterToggleClass() {
        return this._allFiltersShowing ? ALL_FILTERS_SHOWING_CLASS : '';
    }

    /**
     * Returns either: no available pets, no matching pets (based on the user's filters),
     * or the list of matching pets.
     * @returns {TemplateResult}
     */
    get resultsView() {
        // NOTE: Starting to need a state machine. ^_^
        if (this._error) {
            return html`
                <error-pets></error-pets>
            `;
        } else if (this._animals.length === 0 && this._loading) {
            return html`
                <loading-pets></loading-pets>
            `;
        } else if (this._animals.length === 0) {
            if (this.appliedFilters.length === 0) {
                return html`
                    <no-available-pets></no-available-pets>
                `;
            } else {
                return html`
                    <no-matching-pets
                        @reset="${this.clearAllClickHandler}"
                    ></no-matching-pets>
                `;
            }
        } else {
            return html`
                ${this.petListTitle !== ''
                    ? html`
                          <h2 class="embeddablePetList-title txt txt_h2 u-vr6x">
                              ${this.petListTitle}
                          </h2>
                      `
                    : ''}
                <div class="grid grid_gutter m-grid_stretch u-vr6x">
                    ${this._animals.map(
                        (animal, index) =>
                            html`
                                <div class="grid-col grid-col_result">
                                    <!-- we only need one pet card to fire the render handler, thus index === 0 -->
                                    <pet-card
                                        .animal=${animal}
                                        .hideBreed=${this.hideBreed}
                                        .reportRender=${index === 0}
                                        @render="${this.petCardRenderHandler}"
                                    >
                                    </pet-card>
                                </div>
                            `
                    )}
                    ${this._lastPage
                        ? html`
                              <div class="grid-col grid-col_result">
                                  <last-pet-card
                                      cardheight="${this._firstPetCardHeight}"
                                  ></last-pet-card>
                              </div>
                          `
                        : ''}
                </div>
                <dropdown-pagination
                    .currentPage="${this._currentPage}"
                    .totalPages="${this._totalPages}"
                    @change="${this.pageChangeHandler}"
                ></dropdown-pagination>
            `;
        }
    }

    /**
     * Returns a list of chicklets representing user-applied filters
     * @returns {TemplateResult}
     */
    get appliedFiltersView() {
        if (!this.appliedFilters.length > 0) {
            return;
        }

        return html`
            <div class="embeddablePetList-appliedFilters">
                <div class="txt m-txt_bold u-vr2x">
                    Filters Applied
                </div>
                <ul class="wrapList wrapList_gutterSm">
                    ${this.appliedFilters.map((filter, index) => {
                        const isLastFilter =
                            this.appliedFilters.length === index + 1;
                        return html`
                            <li>
                                <div
                                    class="filterTag${isLastFilter
                                        ? ' m-filterTag_last'
                                        : ''}"
                                >
                                    <span class="filterTag-title"
                                        >${filter.label}</span
                                    >
                                    <button
                                        tabindex="0"
                                        class="filterTag-removeBtn"
                                        aria-label="${`Remove filter ${filter.label}: ${filter.value}`}"
                                        @click="${event => {
                                            this.removeFilter(
                                                filter.name,
                                                filter.value
                                            );
                                        }}"
                                    >
                                        Remove Filter
                                        <span
                                            class="icon icon_xs m-icon_colorWhite"
                                        >
                                            <svg
                                                role="presentation"
                                                focusable="false"
                                                viewBox="0 0 24.67 24.67"
                                            >
                                                <title>icon-close</title>
                                                <path
                                                    d="M17.27,12.34l6.4-6.4A3.49,3.49,0,0,0,18.74,1l-6.4,6.4L5.93,1A3.49,3.49,0,0,0,1,5.93l6.4,6.4L1,18.74a3.49,3.49,0,0,0,4.94,4.94l6.4-6.4,6.4,6.4a3.49,3.49,0,0,0,4.94-4.94Z"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                ${isLastFilter
                                    ? html`
                                          <button
                                              tabindex="0"
                                              class="txt txt_link m-txt_heavy m-txt_alignMiddle u-displayInlineBlock"
                                              @click="${this
                                                  .clearAllClickHandler}"
                                          >
                                              Clear All
                                          </button>
                                      `
                                    : ''}
                            </li>
                        `;
                    })}
                </ul>
            </div>
        `;
    }

    /**
     * Returns the button that toggles hidden filters
     * @returns {TemplateResult}
     */
    get toggleButtonView() {
        let buttonText = 'LESS';

        if (!this._allFiltersShowing) {
            buttonText = this.isMobileView ? 'FILTER' : 'MORE';
        }

        return html`
            <div class="embeddablePetList-controls-toggle">
                <button
                    class="fieldBtn m-fieldBtn_full m-fieldBtn_tight"
                    @click="${event => {
                        this._allFiltersShowing = !this._allFiltersShowing;
                    }}"
                >
                    <span class="fieldBtn-label">
                        ${buttonText}
                    </span>
                    <span class="fieldBtn-icon">
                        <span class="icon m-icon_colorPrimary">
                            <svg id="icon-filter" viewBox="0 0 31.9 27.4">
                                <path
                                    d="M1.92,5.72H18.1a3.72,3.72,0,0,0,6.38,0H30a1.9,1.9,0,1,0,0-3.81H24.54a3.71,3.71,0,0,0-6.49,0H1.92a1.9,1.9,0,1,0,0,3.81Z"
                                ></path>
                                <path
                                    d="M30,11.75H13.77a3.72,3.72,0,0,0-6.33,0H1.92a1.9,1.9,0,1,0,0,3.81H7.34a3.71,3.71,0,0,0,6.54,0H30a1.9,1.9,0,1,0,0-3.81Z"
                                ></path>
                                <path
                                    d="M30,21.74H24.53a3.71,3.71,0,0,0-6.44,0H1.92a1.9,1.9,0,1,0,0,3.81H18.09a3.71,3.71,0,0,0,6.43,0H30a1.9,1.9,0,1,0,0-3.81Z"
                                ></path>
                            </svg>
                        </span>
                    </span>
                </button>
            </div>
        `;
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div
                class="embeddablePetList ${this.containerQueryClasses} ${this
                    .filterToggleClass} ${this.loadingClass}"
            >
                <a
                    class="embeddablePetList-logo u-vr10x"
                    href="${this.petfinderUrl}"
                    target="_blank"
                >
                    <div class="embeddablePetList-logo-preLine">
                        Powered by
                    </div>
                    <div class="embeddablePetList-logo-svg">Petfinder</div>
                    <div class="embeddablePetList-logo-tagLine">
                        Adopt your new best friend
                    </div>
                </a>
                <div class="embeddablePetList-controls">
                    ${this.toggleButtonView}
                    <div class="embeddablePetList-controls-filters">
                        <div class="grid grid_gutter">
                            ${this.isFilterHidden('type')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="type"
                                              label="Animal Type"
                                              .multiple=${false}
                                              .items="${this._typeItems}"
                                              .selected="${this._typeApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('breed')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="breed"
                                              label="Breed"
                                              .autosuggest="${true}"
                                              .items="${this._breedItems}"
                                              .selected="${this._breedApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('size')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="size"
                                              label="Size"
                                              .items="${this._sizeItems}"
                                              .selected="${this._sizeApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('age')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="age"
                                              label="Age"
                                              .items="${this._ageItems}"
                                              .selected="${this._ageApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('gender')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="gender"
                                              label="Gender"
                                              .items="${this._genderItems}"
                                              .selected="${this._genderApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('goodWith')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="goodWith"
                                              label="Good With"
                                              .items="${this._goodWithItems}"
                                              .selected="${this
                                                  ._goodWithApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                            ${this.isFilterHidden('care')
                                ? ''
                                : html`
                                      <div class="grid-col grid-col_filter">
                                          <multi-select
                                              identifier="care"
                                              label="Care & Behavior"
                                              .items="${this._careItems.filter(
                                                  item => {
                                                      return (
                                                          item.animal_type ===
                                                              undefined ||
                                                          item.animal_type ==
                                                              this
                                                                  .currentAnimalType
                                                      );
                                                  }
                                              )}"
                                              .selected="${this._careApplied}"
                                              @change="${this
                                                  .filterChangeHandler}"
                                          >
                                          </multi-select>
                                      </div>
                                  `}
                        </div>
                    </div>
                </div>
                ${this.appliedFiltersView}
                <div class="embeddablePetList-results">
                    ${this.resultsView}
                </div>
            </div>
        `;
    }

    /**
     * Life cycle method that is called after the first render.
     * Sets up the JS SDK and runs data queries.
     */
    firstUpdated() {
        // cache the shadow dom's root element after it has rendered
        // so we can focus the first focusable on pagination changes
        this._rootElement = this.shadowRoot.querySelector('.embeddablePetList');

        this._client = new Client({
            token: this.accessToken,
            baseUrl: this.apiBase + '/v2',
        });

        this.getStaticFilters();
        if (this.type.length > 0) {
            this.getAnimalBreeds(this.type[0]);
        }
        this.getAnimals();
    }

    /**
     * Life cycle method that is called after render and lists updated render properties
     * @param  {Object} changedProperties
     */
    updated(changedProperties) {
        changedProperties.forEach((oldValue, propName) => {
            // Whenever the containerWidth property has caused a re-render, get the new pet card height
            if (propName === 'containerWidth') {
                const firstPetCard = this.shadowRoot.querySelector('.petCard');

                // firstPetCard won't have been rendered the first time we hit this code,
                // which is covered by petCardRenderHandler()
                if (firstPetCard) {
                    this._firstPetCardHeight = firstPetCard.getBoundingClientRect().height;
                }
            }
        }, this);
    }
}

customElements.define('pet-scroller', PetScroller);
