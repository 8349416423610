import { LitElement, html } from 'lit-element';

class LoadingPets extends LitElement {
    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="u-textCenter">
                <h2 class="txt txt_h2 u-vr6x">
                    Loading Pets...
                </h2>
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('loading-pets', LoadingPets);
