import { LitElement, html } from 'lit-element';

class ErrorPets extends LitElement {
    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="u-textCenter">
                <h2 class="txt txt_h2 u-vr6x">
                    Unable to reach Petfinder.com
                </h2>
                <p class="txt m-txt_lg u-vr4x">
                    There was an issue retrieving pets from Petfinder.com.
                </p>
                <a
                    href="https://www.petfinder.com/search/"
                    class="btn"
                    target="_blank"
                    >Search on Petfinder</a
                >
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('error-pets', ErrorPets);
