import { LitElement, html } from 'lit-element';

class LastPetCard extends LitElement {
    static get properties() {
        return {
            cardHeight: { type: Number },
        };
    }

    constructor() {
        super();
        this.cardHeight = 0;
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="actionCard m-actionCard_overflowVisible" style="height: ${this.cardHeight}px">
                <a
                    href="https://www.petfinder.com/search/"
                    class="actionCard-overlay actionCard-overlay_link"
                    aria-label="Search Petfinder.com"
                    target="_blank"
                ></a>
                <div class="actionCard-body actionCard-body_split">
                    <div class="actionCard-body-icon">
                        <span class="icon icon_xxl m-icon_colorWhite">
                            <svg
                                role="img"
                                aria-hidden="true"
                                focusable="false"
                            >
                                <use xlink:href="#icon-strokedPaw">
                                    <svg
                                        id="icon-strokedPaw"
                                        viewBox="0 0 42.26 40.83"
                                    >
                                        <title>icon-paw</title>
                                        <path
                                            d="M10.42 17.57c0-3.51-2.34-6.36-5.21-6.36S0 14.06 0 17.57s2.34 6.35 5.21 6.35 5.21-2.85 5.21-6.35zm-5.21 4.57c-1.9 0-3.44-2.05-3.44-4.58s1.54-4.58 3.44-4.58 3.42 2.06 3.42 4.59-1.52 4.57-3.42 4.57zM42.26 17.56c0-3.5-2.34-6.35-5.21-6.35s-5.21 2.85-5.21 6.36a.89.89 0 1 0 1.77 0c0-2.52 1.54-4.58 3.44-4.58s3.44 2.05 3.44 4.58c0 2.52-1.54 4.57-3.44 4.57a.89.89 0 0 0 0 1.78c2.87 0 5.21-2.85 5.21-6.36zM27.63 12.72c2.87 0 5.21-2.85 5.21-6.36S30.49 0 27.63 0s-5.21 2.85-5.21 6.36 2.32 6.36 5.21 6.36zm0-10.94c1.9 0 3.44 2.05 3.44 4.58s-1.56 4.58-3.44 4.58-3.44-2.05-3.44-4.58 1.53-4.58 3.44-4.58zM14.63 12.72c2.87 0 5.21-2.85 5.21-6.36S17.52 0 14.63 0 9.42 2.85 9.42 6.36s2.35 6.36 5.21 6.36zm0-10.94c1.9 0 3.44 2.05 3.44 4.58s-1.53 4.58-3.44 4.58-3.44-2.05-3.44-4.58 1.56-4.58 3.44-4.58zM33.63 28.65l-5.5-9.63c-1.82-3.17-4.31-4.92-7-4.92s-5.18 1.75-7 4.92l-5.5 9.62a11.12 11.12 0 0 0-.9 2 7.54 7.54 0 0 0-.5 2.69.61.61 0 0 0 0 .08 7.5 7.5 0 0 0 7.1 7.41h.3a7.35 7.35 0 0 0 2.91-.59c.66-.26 1.76-.76 2.9-1.3l.41.17a18.59 18.59 0 0 0 7.07 1.71 7.51 7.51 0 0 0 7.14-7.39 9 9 0 0 0-.49-2.72 11.18 11.18 0 0 0-.94-2.05zm-5.72 10.39a15.1 15.1 0 0 1-5.28-1.14l.34-.17 1-.46a.86.86 0 0 0 .18-.14l1-.51.94-.48a.89.89 0 0 0-.8-1.59l-1 .48c-1.55.79-4.14 2.12-5.23 2.57l-.1.07c-.83.39-1.6.73-2.08.92a5.2 5.2 0 0 1-2.4.45h-.09a5.72 5.72 0 0 1-5.42-5.62.35.35 0 0 0 0-.07 5.82 5.82 0 0 1 .4-2.09 9.27 9.27 0 0 1 .77-1.7l5.49-9.65c1.49-2.6 3.43-4 5.47-4s4 1.43 5.47 4l5.53 9.63a9.47 9.47 0 0 1 .77 1.7 7.21 7.21 0 0 1 .42 2.18 5.71 5.71 0 0 1-5.38 5.62z"
                                        ></path>
                                    </svg>
                                </use>
                            </svg>
                        </span>
                    </div>
                    <div>
                        <h3
                            class="txt m-txt_xl m-txt_bold m-txt_colorWhite u-vr2x"
                        >
                            Haven't found the one?
                        </h3>
                        <p class="txt m-txt_colorWhite">
                            Thousands of adoptable pets are waiting for you on
                            Petfinder.com!
                        </p>
                    </div>
                </div>
                <div class="actionCard-footer">
                    <div class="actionCard-footer-cta">
                        <span>Search on Petfinder</span>
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('last-pet-card', LastPetCard);
