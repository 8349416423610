import { LitElement, html } from 'lit-element';

class NoAvailablePets extends LitElement {
    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="u-textCenter">
                <h2 class="txt txt_h2 u-vr6x">
                    No Pets Here Right Now
                </h2>
                <p class="txt m-txt_lg u-vr4x">
                    There are no pets to see right now. You can check back
                    later, or browse thousands of adoptable pets on
                    Petfinder.com.
                </p>
                <a
                    href="https://www.petfinder.com/search/"
                    class="btn"
                    target="_blank"
                    >Search on Petfinder</a
                >
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('no-available-pets', NoAvailablePets);
