/**
 * Exports event constants which are shared strings.
 */

// Used in Chrome, Firefox
export const KEY_ARROW_DOWN = 'ArrowDown';

// Used in IE11
export const KEY_DOWN = 'Down';

// Used in Chrome, Firefox
export const KEY_ARROW_UP = 'ArrowUp';

// Used in IE11
export const KEY_UP = 'Up';

// Used in Chrome, Firefox
export const KEY_ARROW_LEFT = 'ArrowLeft';

// Used in IE11
export const KEY_LEFT = 'Left';

// Used in Chrome, Firefox
export const KEY_ARROW_RIGHT = 'ArrowRight';

export const KEY_RIGHT = 'Right';

// Used in Chrome, Firefox
export const KEY_ESCAPE = 'Escape';

// Used in IE11
export const KEY_ESC = 'Esc';

// Used in Chrome, Firefox
export const KEY_ENTER = 'Enter';

// Used in Chrome, Firefox
export const KEY_SPACE = ' ';

// Used in IE11
export const KEY_SPACE_BAR = 'Spacebar';

// Used in Chrome, Firefox
export const KEY_TAB = 'Tab';

// Used in Chrome, Firefox
export const KEY_BACKSPACE = 'Backspace';

// Used in Chrome, Firefox
export const KEY_COMMA = ',';

// Used in Chrome, Firefox
export const KEY_CAPSLOCK = 'CapsLock';
