import { LitElement, html } from 'lit-element';

class NoMatchingPets extends LitElement {
    static get properties() {
        return {};
    }

    constructor() {
        super();
    }
    /**
     * Handles Reset Search click
     * @param  {Object} event
     */
    resetClickHandler(event) {
        var event = new CustomEvent('reset');
        this.dispatchEvent(event);
        event.preventDefault();
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="u-textCenter">
                <h2 class="txt txt_h2 u-vr6x">
                    No Matching Pets
                </h2>
                <p class="txt m-txt_lg u-vr6x">
                    Try broadening your search or browse thousands of adoptable
                    pets on Petfinder.com.
                </p>
                <div class="grid grid_gutterLg m-grid_centerJustify">
                    <div
                        class="grid-col grid-col_1of2minMd grid-col_1of3minLg grid-col_1of4minXl"
                    >
                        <button
                            class="btn btn_borderPrimary m-btn_full"
                            @click="${this.resetClickHandler}"
                        >
                            Reset Search
                        </button>
                    </div>
                    <div
                        class="grid-col grid-col_1of2minMd grid-col_1of3minLg grid-col_1of4minXl"
                    >
                        <a
                            href="https://www.petfinder.com/search/"
                            class="btn m-btn_full"
                            target="_blank"
                            >Search on Petfinder</a
                        >
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('no-matching-pets', NoMatchingPets);
