import { pollUntilConditionMet } from './util';

export function isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export function matchesElementOrDescendant(element, target) {
    if (Array.isArray(element)) {
        const length = element.length;
        for (let i = 0; i < length; i++) {
            if (matchesElementOrDescendant(element[i], target)) {
                return true;
            }
        }
        return false;
    }
    if (element === target) {
        return true;
    }
    if (isDescendant(element, target)) {
        return true;
    }
    return false;
}

export function isElement(el = {}) {
    return el.nodeType === Node.ELEMENT_NODE;
}

export async function isElementConnected(element) {
    pollUntilConditionMet(() => element.isCustom, 0, 100);
}

export function buildCustomEvent(event, detail, bubbles = true, ...args) {
    return new CustomEvent(event, {
        bubbles,
        detail,
        args,
    });
}

export function isCustomElement(el = {}) {
    return isElement(el) && el.isCustom;
}

export function parseJsonAttribute(el, attr) {
    try {
        return JSON.parse(el.getAttribute(attr));
    } catch (e) {
        return el.getAttribute(attr);
    }
}

export function debounce(fn, wait) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => fn.apply(this, args), wait || 1);
    };
}

export function getPseudoContent(el, pseudo) {
    return window
        .getComputedStyle(el, pseudo)
        .getPropertyValue('content')
        .replace(/"/g, '');
}

export function getCurrentBreakpoint() {
    try {
        return getPseudoContent(document.body, ':after');
    } catch (err) {
        return null;
    }
}

export function getGlobalHeaderHeight() {
    return document.querySelector('#Header').clientHeight;
}

export function getBodyHeight() {
    return document.querySelector('#Main').clientHeight;
}

export function getGlobalFooterHeight() {
    return document.querySelector('#Footer').clientHeight;
}

/**
 * Escapes a selector with special characters.  Useful for instances
 * in which the API returns data related to inputs which have special
 * characters in their name or id, such as edit_animal[name]
 *
 * @param {String} selector
 * @return {String} escaped selector
 */
export function escapeSelector(selector) {
    return selector.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * Takes a string attr value like i.e. data-new-values="key: val, key2: val, val, val; key3: val;"
 * and returns an object with those values.
 * @param {string} str from element attr
 * @return {Object}
 */
export function parseParamsStringToObject(str) {
    const obj = {};
    const paramArr = str.replace(/\s/g, '').split(';');
    paramArr.pop(); // Removes last empty array item

    const paramKeysArr = paramArr.map(item =>
        item.substr(0, item.indexOf(':'))
    );
    const paramValuesArr = paramArr.map(item =>
        item.substr(item.indexOf(':') + 1, item.length)
    );

    paramKeysArr.forEach((key, index) => {
        // TODO do we need to turn things into arrays?
        // obj[key] = paramValuesArr[index].split(',').length > 1 ?
        //     paramValuesArr[index].split(',') :
        //     paramValuesArr[index];
        obj[key] = paramValuesArr[index];
    });

    return obj;
}

/**
 * Setup a MutationObserver on a given target element. You must also provide a function that gets
 * mutations as a param. You must also pass in a config object for the MutationObserver.
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * @param {element} target element
 * @param {function} func function to perform on mutations
 * @param {Object} config must contain MutationObserver options
 * @return {MutationObserver} so you can have access to the observer elsewhere
 */
export function watchForMutations(target, func, config) {
    if (typeof func !== 'function') {
        throw new Error(
            'The second argument of watchForMutations needs to be a function'
        );
    }
    const observer = new MutationObserver(func);
    observer.observe(target, config);
    return observer;
}

/**
 * Get top left offset from document of provided element
 * @param {element} el
 * @return {Object}
 */
export function offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        right: rect.right,
    };
}

const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

/* eslint-disable */
const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;',
};
/* eslint-enable */

function escapeChar(chr) {
    return escape[chr];
}

export function escapeExpression(string) {
    if (typeof string !== 'string') {
        return;
    }
    if (!possible.test(string)) {
        return string;
    }
    return string.replace(badChars, escapeChar);
}

/**
 * Creates a hidden input element and uses exec command to copy passed text
 * @param {string} text
 * @param {element} contextEl where the hidden input is created
 */
export function copyTextToClipboard(text, contextEl = document.body) {
    // create element
    const hiddenInput = document.createElement('input');
    hiddenInput.classList.add('u-isVisuallyHidden');
    hiddenInput.setAttribute('value', text);
    hiddenInput.readOnly = true;

    // place in body
    contextEl.appendChild(hiddenInput);

    // select
    hiddenInput.select();
    hiddenInput.setSelectionRange(0, text.length); // needed for ios

    // add to body
    document.execCommand('copy');

    // cleanup
    contextEl.removeChild(hiddenInput);
}

/**
 * @param {string} text
 * @returns {Promse}
 */
export async function asyncCopyTextToClipboard(text) {
    const hiddenInput = document.createElement('textarea');
    hiddenInput.value = text;
    hiddenInput.setAttribute('readonly', '');
    hiddenInput.classList.add('u-isVisuallyHidden');

    document.body.appendChild(hiddenInput);
    hiddenInput.select();

    if (navigator.clipboard) {
        try {
            const result = await navigator.clipboard.writeText(
                hiddenInput.value
            );

            document.body.removeChild(hiddenInput);
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    } else {
        document.execCommand('copy');
        document.body.removeChild(hiddenInput);
        return Promise.resolve(text);
    }
}

/**
 * Takes an object of event keys, an array of elements, and a object map key to handler
 * and loops thru to add event listeners
 * @param {Object} events
 * @param {Array} elements
 * @param {Object} handlerMap
 */
export function addListeners(events, elements, handlerMap) {
    Object.keys(events).forEach(event => {
        elements.forEach(el => {
            el.addEventListener(event, handlerMap[event]);
        });
    });
}

/**
 * Removes event listeners based on addListeners params
 * @param {Object} events
 * @param {Array} elements
 * @param {Object} handlerMap
 */
export function removeListeners(events, elements, handlerMap) {
    Object.keys(events).forEach(event => {
        elements.forEach(el => {
            el.removeEventListener(event, handlerMap[event]);
        });
    });
}

/**
 * Utility to get the document scroll top in all modern browsers
 * @returns {number}
 */
export function getNumPixelsScrolledFromTop() {
    return (
        window.scrollY ||
        window.pageYOffset ||
        document.body.scrollTop +
            ((document.documentElement && document.documentElement.scrollTop) ||
                0)
    );
}

/**
 * Takes string that can contain html and returns only the inner text
 * @param {string} str
 * @returns {string}
 */
export function getTextOnlyFromHTMLString(str) {
    const div = document.createElement('div');
    div.innerHTML = str;

    return div.innerText;
}
