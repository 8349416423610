import { LitElement, html } from 'lit-element';
import he from 'he';

class PetCard extends LitElement {
    static get properties() {
        return {
            animal: { type: Object },
            hideBreed: { type: Boolean },
            reportRender: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.animal = {};
        this.hideBreed = false;
        this.reportRender = false;
    }

    /**
     * Lets the parent component know the pet card has been rendered
     */
    fireRenderedEvent() {
        const event = new CustomEvent('render', {
            detail: {
                height: this.querySelector('.petCard').getBoundingClientRect().height,
            },
        });
        this.dispatchEvent(event);
    }

    /**
     * Returns the pet image src
     * @returns {String}
     */
    get image() {
        if (!Array.isArray(this.animal.photos) || this.animal.photos.length < 1) {
            return null;
        }

        // if cropped photo of animal exists, return that instead of this.animal.photos[0]['large']
        if (this.animal.primary_photo_cropped) {
            return this.animal.primary_photo_cropped.large;
        }

        return this.animal.photos[0]['large'];
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <a
                class="petCard ${this.animal.videos.length
                    ? 's-petCard_hasVideo'
                    : ''}"
                target="_blank"
                href="${this.animal.url}"
            >
                <div class="petCard-media">
                    ${this.image
                        ? html`
                              <img
                                  alt="${he.unescape(this.animal.name)}"
                                  class="petCard-media-img"
                                  src="${this.image}"
                              />
                          `
                        : ''}
                </div>
                <div class="petCard-body">
                    <div class="petCard-body-details">
                        <div class="petCard-body-details-hdg">
                            <span
                                class="txt m-txt_xl m-txt_colorPrimary u-vr1x"
                            >
                                ${he.unescape(this.animal.name)}
                            </span>
                            ${this.animal.videos.length
                                ? html`
                                      <div
                                          class="petCard-body-details-hdg-videoIcon"
                                          title="Has video"
                                      ></div>
                                  `
                                : ''}
                        </div>
                        <div class="txt m-txt_colorPrimaryS2">
                            <ul>
                                <li>
                                    <ul
                                        class="hrArray hrArray_bulletDividedSingleLine m-hrArray_center"
                                    >
                                        <li>${this.animal.age}</li>
                                        ${this.hideBreed
                                            ? ''
                                            : html`
                                                  <li>
                                                      <span
                                                          class="txt m-txt_colorPrimaryS2"
                                                          >${this.animal.breeds
                                                              .primary}
                                                          ${this.animal.breeds
                                                              .mixed
                                                              ? 'Mix'
                                                              : ''}</span
                                                      >
                                                  </li>
                                              `}
                                    </ul>
                                </li>
                                <li>
                                    <div
                                        class="txt m-txt_colorPrimaryS2 m-txt_ellipsisOverflow"
                                    >
                                        ${this.animal.contact.address.city},
                                        ${this.animal.contact.address.state}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }

    /**
     * Life cycle method that is called after the first render.
     * Fires the event that lets the parent know it has finished rendering.
     */
    firstUpdated() {
        if (this.reportRender) {
            this.fireRenderedEvent();
        }
    }
}

customElements.define('pet-card', PetCard);
