export default function assert(condition, message) {
    if (!condition) {
        throw new Error(message);
    }
}

function assertType(condition, message) {
    if (!condition) {
        throw new TypeError(message);
    }
}

assert.type = assertType;
