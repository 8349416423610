import { LitElement, html, customElement } from 'lit-element';
import './multi-select';

class DropdownPagination extends LitElement {
    static get properties() {
        return {
            currentPage: { type: Number },
            totalPages: { type: Number },
            pagesOnEachSide: { type: Number },
        };
    }

    constructor() {
        super();
        this.currentPage = 1;
        this.totalPages = 1;
        this.pagesOnEachSide = 4;
    }

    /**
     * Is the current page also the first page?
     * @returns {Boolean}
     */
    get isFirstPage() {
        return this.currentPage === 1;
    }

    /**
     * Is the current page also the last page?
     * @returns {Boolean}
     */
    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    /**
     * Is there only one page?
     * @returns {Boolean}
     */
    get isOnlyPage() {
        return this.totalPages === 1;
    }

    /**
     * Sets up the options for the pagination dropdown, including
     * ellipses when the list is too long.
     * @returns {Array}
     */
    get pageList() {
        const pages = [];

        const firstPaginationPage = Math.max(
            1,
            this.currentPage - this.pagesOnEachSide
        );
        const lastPaginationPage = Math.min(
            this.currentPage + this.pagesOnEachSide,
            this.totalPages
        );

        // create initial range
        for (let i = firstPaginationPage; i <= lastPaginationPage; i++) {
            pages.push({
                label: i,
                value: i,
            });
        }

        // should we display ... after first number
        if (firstPaginationPage > 2) {
            pages.unshift({
                label: '&hellip;',
                value: null,
                disabled: true,
            });
        }

        // should we display additional "1"
        if (firstPaginationPage > 1) {
            pages.unshift({
                label: 1,
                value: 1,
            });
        }

        // should we display ... before last number
        if (lastPaginationPage < this.totalPages) {
            pages.push({
                label: '&hellip;',
                value: null,
                disbled: true,
            });
        }

        // should we display last number
        if (lastPaginationPage < this.totalPages) {
            pages.push({
                label: this.totalPages,
                value: this.totalPages,
            });
        }

        return pages;
    }

    /**
     * Handles a Previous click.
     * @param  {Object} event
     */
    prevClickHandler(event) {
        this.fireChangeEvent(this.currentPage - 1);
        event.preventDefault();
    }

    /**
     * Handles a Next click.
     * @param  {Object} event
     */
    nextClickHandler(event) {
        this.fireChangeEvent(this.currentPage + 1);
        event.preventDefault();
    }

    /**
     * Handles a change in the pagination dropdown.
     * @param  {Object} event
     */
    dropdownChangeHandler(event) {
        this.fireChangeEvent(event.detail.values[0]);
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Lets the parent component know the selection has changed.
     * @param  {Object} event
     */
    fireChangeEvent(page) {
        const event = new CustomEvent('change', {
            detail: {
                page,
            },
            bubbles: true,
        });
        this.dispatchEvent(event);
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div class="pagination">
                <div class="grid grid_gutterLg m-grid_rightJustify">
                    <div
                        class="grid-col grid-col_1of2 grid-col_1of3minMd grid-col_1of5minLg grid-col_1of6minXl"
                    >
                        ${this.isFirstPage
                            ? ''
                            : html`
                                  <button
                                      class="fieldBtn fieldBtn_altHover m-fieldBtn_tight m-fieldBtn_full"
                                      @click="${this.prevClickHandler}"
                                      type="button"
                                  >
                                      <span class="fieldBtn-icon">
                                          <span
                                              class="icon icon_sm m-icon_colorPrimary"
                                          >
                                              <svg role="img">
                                                  <use
                                                      xlink:href="#icon-chevronLeft"
                                                  />
                                                  <svg
                                                      id="icon-chevronLeft"
                                                      viewBox="0 0 15.8 24.7"
                                                  >
                                                      <title>
                                                          icon-chevronLeft
                                                      </title>
                                                      <path
                                                          d="M1,14.7L1,14.7L1,14.7l8.8,8.9c1.4,1.4,3.6,1.4,4.9,0s1.4-3.6,0-4.9l-6.3-6.5l6.5-6.4c1.2-1.3,1.2-3.4,0-4.7
                                          c-1.3-1.4-3.5-1.6-4.9-0.3L1,9.8C-0.3,11.1-0.3,13.3,1,14.7L1,14.7z"
                                                      ></path>
                                                  </svg>
                                              </svg>
                                          </span>
                                      </span>
                                      <span class="fieldBtn-label"
                                          >Previous</span
                                      >
                                  </button>
                              `}
                    </div>
                    ${this.isOnlyPage
                        ? ''
                        : html`
                              <div
                                  class="grid-col grid-col_1of3minMd grid-col_1of4minLg grid-col_1of6minXl m-grid-col_orderFirstMaxMd"
                              >
                                  <multi-select
                                      placeHolder="Page ${this
                                          .currentPage}/${this.totalPages}"
                                      .displayOnlyPlaceholder="${true}"
                                      .multiple="${false}"
                                      .items="${this.pageList}"
                                      .selected="[${this.currentPage}]"
                                      @change="${this.dropdownChangeHandler}"
                                  >
                                  </multi-select>
                              </div>
                          `}
                    <div
                        class="grid-col grid-col_1of2 grid-col_1of3minMd grid-col_1of5minLg grid-col_1of6minXl"
                    >
                        ${this.isLastPage
                            ? ''
                            : html`
                                  <button
                                      class="fieldBtn fieldBtn_altHover m-fieldBtn_iconRt m-fieldBtn_tight m-fieldBtn_full"
                                      @click="${this.nextClickHandler}"
                                      type="button"
                                  >
                                      <span class="fieldBtn-label">Next</span>
                                      <span class="fieldBtn-icon">
                                          <span
                                              class="icon icon_sm m-icon_colorPrimary"
                                          >
                                              <svg role="img">
                                                  <use
                                                      xlink:href="#icon-chevronRight"
                                                  />
                                                  <svg
                                                      id="icon-chevronRight"
                                                      viewBox="0 0 15.86 24.62"
                                                  >
                                                      <title>
                                                          icon-chevronRight
                                                      </title>
                                                      <path
                                                          d="M14.87 14.69a3.48 3.48 0 0 0 0-4.86L5.95.91a3.49 3.49 0 0 0-4.93 4.93l6.4 6.42-6.4 6.4a3.493 3.493 0 1 0 4.94 4.94l8.87-8.86z"
                                                      ></path>
                                                  </svg>
                                              </svg>
                                          </span>
                                      </span>
                                  </button>
                              `}
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }
}

customElements.define('dropdown-pagination', DropdownPagination);
