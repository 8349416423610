import { LitElement, html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import {
    KEY_ENTER,
    KEY_ESCAPE,
    KEY_ESC,
    KEY_ARROW_UP,
    KEY_UP,
    KEY_ARROW_DOWN,
    KEY_DOWN,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../../../core/scripts/constants/keyboard';
import { isEventRelatedTargetWithinCurrentTarget } from '../../../../core/scripts/util/react';

const ID_REGEX = / |\//g;

/**
 * Container component for custom multi-select dropdowns
 * @class MultiSelect
 * @extends LitElement
 */
class MultiSelect extends LitElement {
    static get properties() {
        return {
            // Public
            identifier: { type: String },
            label: { type: String },
            placeholder: { type: String },
            displayOnlyPlaceholder: { type: Boolean },
            items: { type: Array },
            multiple: { type: Boolean },
            selected: { type: Array },
            autosuggest: { type: Boolean },

            // Internal
            _focusedItemIndex: { type: Number },
            _isOpened: { type: Boolean },
            _inputText: { type: String },
        };
    }

    constructor() {
        super();

        // Public
        this.identifier = '';
        this.label = '';
        this.placeholder = 'Any';
        this.displayOnlyPlaceholder = false;
        this.items = [];
        this.multiple = true;
        this.selected = [];
        this.autosuggest = false;

        // Internal
        this._focusedItemIndex = -1;
        this._isOpened = false;
        this._inputText = '';
    }

    /**
     * Opens or closes the popup when the field is clicked
     */
    fieldClickHandler() {
        this._isOpened ? this.close() : this.open();

        if (this.autosuggest) {
            this.focusInput();
        }
    }

    /**
     * Handles keyboard presses in the popup
     * @param  {Object} event
     */
    keyDownHandler(event) {
        switch (event.key) {
            case KEY_ENTER:
            case 13:
                this.handleEnterKey();
                break;
            case KEY_ESCAPE:
            case KEY_ESC:
            case 27:
                this.handleEscapeKey();
                break;
            case KEY_ARROW_UP:
            case KEY_UP:
            case 38:
                event.altKey
                    ? this.handleAltArrowUpKey()
                    : this.handleArrowUpKey();
                break;
            case KEY_ARROW_DOWN:
            case KEY_DOWN:
            case 40:
                if (!this._isOpened) {
                    this.open();
                }
                event.altKey
                    ? this.handleAltArrowDownKey()
                    : this.handleArrowDownKey();
                break;
            default:
                return;
        }

        event.preventDefault();
    }

    inputChangeHandler(event) {
        this._inputText = event.target.value;
        event.preventDefault();
    }

    /**
     * Selects an item
     */
    handleEnterKey() {
        if (this._isOpened) {
            const focusedItem = this.items[this._focusedItemIndex];
            this.selectItem(focusedItem);
        } else {
            this.open();
        }
    }

    /**
     * Try to scoll the active descendent into view in its parent scrolling container
     */
    _scrollFocusedListBoxOptionIntoView() {
        try {
            const el = this.querySelector(`#${this.activeDescendant}`);
            if (!el) {
                return;
            }
            el.scrollIntoView({ block: 'nearest' });
        } catch (error) {}
    }

    /**
     * Navigates down in the popup
     */
    handleArrowDownKey() {
        if (this.items.length > 0) {
            this._focusedItemIndex =
                this._focusedItemIndex < this.items.length - 1
                    ? this._focusedItemIndex + 1
                    : 0;

            this._scrollFocusedListBoxOptionIntoView();
        }
    }

    /**
     * Navigates up in the popup
     */
    handleArrowUpKey() {
        if (this.items.length > 0) {
            this._focusedItemIndex =
                this._focusedItemIndex > 0
                    ? this._focusedItemIndex - 1
                    : this.items.length - 1;

            this._scrollFocusedListBoxOptionIntoView();
        }
    }

    /**
     * Allows opening the popup with Alt+Down
     */
    handleAltArrowDownKey() {
        this.open();
    }

    /**
     * Allows closing the popup with Alt+Up
     */
    handleAltArrowUpKey() {
        this.close();
    }

    /**
     * Closes the popup on Esc
     */
    handleEscapeKey() {
        this.close(true);
    }

    /**
     * Retrieves a popup item by its value
     * @param  {string} value
     * @returns {Object}
     */
    getItemByValue(value) {
        return this.items.find(
            item => item.value && item.value.toString(10) === value.toString(10)
        );
    }

    /**
     * Handles clicks within the popup
     * @param  {Object} event
     */
    listClickHandler(event) {
        let uiItem = event.target;

        while (uiItem && uiItem.getAttribute('role') !== 'option') {
            uiItem = uiItem.parentNode;
        }
        const itemClicked = this.getItemByValue(uiItem.getAttribute('value'));

        if (itemClicked.selected) {
            this.deselectItem(itemClicked);
        } else {
            this.selectItem(itemClicked);
        }
    }

    /**
     * Handles a Clear click
     * @param  {Object} event
     */
    clearClickHandler(event) {
        event.stopPropagation();
        this.clearSelected();
    }

    clearKeyDownHandler(event) {
        switch (event.key) {
            case KEY_ENTER:
            case KEY_SPACE:
            case KEY_SPACE_BAR:
                event.stopPropagation();
                event.preventDefault();
                this.clearSelected();
                break;
        }
    }

    clearSelected() {
        this.items.forEach(item => {
            item.selected = false;
        });

        this.fireChangeEvent();
    }

    /**
     * Sets an item to selected
     * @param  {Object} item
     */
    selectItem(item) {
        if (!this.multiple) {
            this.items.forEach(item => {
                item.selected = false;
            });
        }

        if (item) {
            item.selected = true;
        }

        this.fireChangeEvent();

        if (this.autosuggest) {
            this.focusInput();
        } else {
            this.focusTrigger();
        }

        if (!this.multiple) {
            // Only want to close when this is single select
            this.close();
        }
    }

    /**
     * Deselects an item
     * @param  {Object} item
     */
    deselectItem(item) {
        if (item) {
            item.selected = false;
        }

        this.fireChangeEvent();

        if (this.autosuggest) {
            this.focusInput();
        } else {
            this.focusTrigger();
        }
    }

    /**
     * Lets the parent component know the selection has changed
     */
    fireChangeEvent() {
        const event = new CustomEvent('change', {
            detail: {
                identifier: this.identifier,
                values: this.selectedItemValues(),
            },
            bubbles: true,
        });
        this.dispatchEvent(event);
    }

    /**
     * Opens the popup
     */
    open() {
        this._isOpened = true;

        if (this.autosuggest) {
            this.focusInput();
        }
    }

    /**
     * Closes the popup
     */
    close(returnFocusToTrigger = false) {
        this._isOpened = false;
        this._focusedItemIndex = -1;

        if (returnFocusToTrigger) {
            this.focusTrigger();
        }
    }

    focusInput() {
        setTimeout(() => {
            this.querySelector('input').focus();
        }, 0);
    }

    focusTrigger() {
        setTimeout(() => {
            this.querySelector('.multiselect-field').focus();
        }, 0);
    }

    /**
     * Returns all items marked as selected
     */
    selectedItems() {
        return this.items.filter(item => item.selected === true);
    }

    /**
     * Returns the above as just the labels
     */
    selectedItemLabels() {
        return this.selectedItems().map(item => item.label);
    }

    /**
     * Returns the above as just the values
     */
    selectedItemValues() {
        return this.selectedItems().map(item => item.value);
    }

    /**
     * Returns the index of the search query in the given label
     * @param  {string} label
     */
    autoSuggestSearchIndex(label) {
        return label.toLowerCase().indexOf(this._inputText.toLowerCase());
    }

    /**
     * Determines whether the listbox item should display
     * @param  {string} label
     * @returns {Boolean}
     */
    isSuggestedItem(label) {
        return !this.autosuggest || this.autoSuggestSearchIndex(label) > -1;
    }

    /**
     * If autosuggest is turned on, listbox items should display matched text
     * in bold, otherwise this just returns the given label text
     * @param  {string} label
     * @returns {TemplateResult}
     */
    suggestedItemContent(label) {
        if (!this.autosuggest) {
            return html`
                ${unsafeHTML(label)}
            `;
        }

        const searchIndex = this.autoSuggestSearchIndex(label);
        const indexOfRestOfLabel = searchIndex + this._inputText.length;

        // prettier-ignore
        return html`
            <p>${label.substring(0, searchIndex)}<span class="txt m-txt_heavy">${label.substring(searchIndex, indexOfRestOfLabel)}</span>${label.substring(indexOfRestOfLabel)}</p>
        `;
    }

    /**
     * Hides list items if they are not an autosuggested result.
     * @param  {Object} item
     * @returns {String}
     */
    itemHiddenClass(item) {
        return this.isSuggestedItem(item.label) ? '' : 'u-isVisuallyHidden';
    }

    /**
     * Adds a class to an active list item to indicate focus.
     * @param  {Object} item
     * @returns {String}
     */
    itemFocusedClass(item) {
        return this._focusedItemIndex > -1 &&
            this.items[this._focusedItemIndex].value === item.value
            ? 'multiselect-listItem_focus'
            : '';
    }

    processValue(value) {
        if (value && typeof value === 'string') {
            return value.replace(ID_REGEX, '_');
        }
        return value;
    }

    /**
     * Returns the id of the currently focused list item.
     * @returns {String}
     */
    get activeDescendant() {
        return this._focusedItemIndex > -1
            ? `${this.identifier}_${this.processValue(
                  this.items[this._focusedItemIndex].value
              )}`
            : '';
    }

    /**
     * Close listbox when blurred outside
     * @param {Event} event
     */
    blurHandler(event) {
        if (isEventRelatedTargetWithinCurrentTarget(event)) {
            return;
        }

        this.close();
    }

    /**
     * LitElement life cycle event handler for rendering the markup, using lit-html
     * // WARNING:
     * Visually hiding things so that we we don't have inconsistent blur events focusing the
     * body and triggering close() when elements are display none-ed...
     * @returns {TemplateResult}
     */
    render() {
        return html`
            <div
                class="multiselectLabel ${this.label
                    ? ''
                    : 'u-isVisuallyHidden'}"
            >
                <label
                    id="${this.identifier}"
                    class="txt m-txt_md m-txt_bold m-txt_uppercase m-txt_alignCenter"
                >
                    ${this.label}
                </label>
            </div>
            <div class="multiselect ${this._isOpened ? 'u-isOpen' : ''}">
                <div
                    class="multiselect-field ${this.autosuggest &&
                    this._isOpened
                        ? 'u-isVisuallyHidden'
                        : ''}"
                    tabindex="${!this._isOpened ? 0 : -1}"
                    aria-hidden="${this.autosuggest && this._isOpened
                        ? true
                        : false}"
                    role="combobox"
                    aria-autocomplete="list"
                    ,
                    aria-haspopup="true"
                    aria-owns="${this.identifier}_listbox"
                    aria-activedescendant="${this.activeDescendant}"
                    aria-labelledby="${this.identifier}"
                    @click="${this.fieldClickHandler}"
                    @keydown="${this.keyDownHandler}"
                >
                    <div class="multiselect-field-selection">
                        ${this.displayOnlyPlaceholder ||
                        this.selected.length === 0
                            ? this.placeholder
                            : this.selectedItemLabels().join(', ')}
                    </div>
                    ${!this.displayOnlyPlaceholder && this.selected.length > 1
                        ? html`
                              <button
                                  class="multiselect-field-clearBtn"
                                  @click="${this.clearClickHandler}"
                                  @keydown="${this.clearKeyDownHandler}"
                              >
                                  Clear
                              </button>
                          `
                        : ''}
                </div>
                ${this.autosuggest
                    ? html`
                          <div
                              class="multiselect-search ${this._isOpened
                                  ? ''
                                  : 'u-isVisuallyHidden'}"
                              aria-expanded="${this._isOpened}"
                          >
                              <div
                                  id="${this.identifier}-description"
                                  class="u-isVisuallyHidden"
                                  aria-hidden="true"
                              >
                                  This is a filterable list field. The list of
                                  options will be filtered based on your typed
                                  query. Use up and down arrow keys or mobile
                                  controls to navigate the list. Use enter to
                                  select option. Use escape to cancel.
                              </div>

                              <input
                                  type="text"
                                  class="multiselect-search-input"
                                  placeholder="Search By Breed"
                                  @input="${this.inputChangeHandler}"
                                  @keydown="${this.keyDownHandler}"
                                  role="combobox"
                                  aria-autocomplete="both"
                                  aria-owns="${this.identifier}_listbox"
                                  aria-activedescendant="${this
                                      .activeDescendant}"
                                  aria-describedby="${this
                                      .identifier}-description"
                                  aria-labelledby="${this.identifier}"
                                  tabindex="${this._isOpened ? 0 : -1}"
                                  value="${this._inputText}"
                              />
                          </div>
                      `
                    : ''}
                <div
                    id="${this.identifier}_popup"
                    class="multiselect-popup ${this._isOpened
                        ? ''
                        : 'u-isVisuallyHidden'}"
                    aria-expanded="${this._isOpened}"
                >
                    <div
                        class="multiselect-popup-list ${this.multiple
                            ? ''
                            : 'multiselect-popup-list_single'}"
                        role="listbox"
                        id="${this.identifier}_listbox"
                        aria-multiselectable="true"
                        @click="${this.listClickHandler}"
                    >
                        ${this.items.map(
                            item =>
                                html`
                                    <div
                                        id="${this
                                            .identifier}_${this.processValue(
                                            item.value
                                        )}"
                                        class="multiselect-listItem ${this.itemFocusedClass(
                                            item
                                        )} ${this.itemHiddenClass(item)}"
                                        value="${item.value}"
                                        ?selected="${item.selected === true}"
                                        aria-selected="${item.selected ===
                                            true}"
                                        role="option"
                                        tabindex="-1"
                                    >
                                        ${this.suggestedItemContent(item.label)}
                                    </div>
                                `
                        )}
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * Use the parent shadow-root
     */
    createRenderRoot() {
        return this;
    }

    /**
     * LitElement life cycle event handler for updated properties
     * @param  {Array} changedProperties
     */
    updated(changedProperties) {
        changedProperties.forEach((oldValue, propName) => {
            if (propName === 'selected') {
                this.items.forEach(item => {
                    item.selected = this.selected.includes(item.value);
                });

                this.requestUpdate();
            }
        });
    }

    firstUpdated(changedProperties) {
        const wrapper = this.querySelector('.multiselect');
        wrapper.addEventListener('focusout', event => this.blurHandler(event));
    }
}

customElements.define('multi-select', MultiSelect);
