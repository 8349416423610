/**
 * Returns an object with a key of inert or disabled to get around react not knowing how
 * to handler inert atm. Spread the object property onto your component or element.
 * @param {bool} test
 * @returns {object}
 */
export const setInertIf = test => {
    return {
        [test ? 'inert' : 'data-inert-disabled']: '',
    };
};

/**
 * Helper to check if a related target of an event is within the container
 * runs callback if it is not
 * @param {node} container
 * @param {Event} event
 * @param {function} callback
 */
// TODO: Don't use this, we should use this method in the future for checking when to close
// TODO: on a blur event https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
export function isFocusWithinContainer(container, event, callback) {
    const relatedTarget =
        event.relatedTarget ||
        event.explicitOriginalTarget ||
        document.activeElement; // IE11, Safari

    if (
        !relatedTarget ||
        (!event.currentTarget.contains(relatedTarget) &&
            !container.contains(relatedTarget))
    ) {
        // TODO: Using a magic number here.
        // Figure out if it's really necessary or should it be happening elsewhere?
        setTimeout(callback, 250); // Safari
    }
}

/**
 * Takes an event, usually a blur event and returns boolean whether the related target/next element
 * to get focus is within the current target of the event
 * @param {Event} event
 * @returns {bool}
 */
// TODO: Don't use this, we should use this method in the future for checking when to close
// TODO: on a blur event https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
export function isEventRelatedTargetWithinCurrentTarget(event) {
    const relatedTarget =
        event.relatedTarget ||
        event.explicitOriginalTarget ||
        document.activeElement; // IE11, Safari
    const currentTarget = event.currentTarget;

    return relatedTarget && currentTarget.contains(relatedTarget);
}
