import queryFocusable from 'ally.js/query/focusable';
import maintainDisabled from 'ally.js/maintain/disabled';
import maintainHidden from 'ally.js/maintain/hidden';
import platform from 'platform';

import assert from '../assert';
import { isElement } from '../../util/dom';

/**
 * Test for low performance device/platform
 * @method isLowPerformancePlatform
 * @private
 * @returns {bool}
 */
const isLowPerformancePlatform = () => {
    return platform.name === 'IE' && Number(platform.version) < 12;
};

/**
 * Handles trapping focus on low performance platforms without ally.js
 * @method trapFocusForLowPerformancePlatforms
 * @private
 * @param {array} filterElements
 * @returns {array}
 */
const trapFocusForLowPerformancePlatforms = filterElements => {
    const tabbables = filterElements.map(element => {
        return queryFocusable({
            context: element,
            includeContext: false,
            strategy: 'quick',
        });
    });

    const svgs = Array.from(document.querySelectorAll('svg'));

    const trappedElements = queryFocusable({
        context: 'body',
        includeContext: false,
        strategy: 'quick',
    })
        .concat(svgs)
        .map(element => {
            const newElement = element;

            newElement._tabindex = newElement.getAttribute('tabindex');
            newElement.setAttribute('tabindex', '-1');

            if (newElement.tagName === 'SVG' || newElement.tagName === 'svg') {
                if (
                    newElement.hasAttribute('focusable') &&
                    newElement.getAttribute('focusable') === 'false'
                ) {
                    newElement._focusableFalse = true;
                } else {
                    newElement.setAttribute('focusable', 'false');
                }
            }

            return newElement;
        });

    tabbables.forEach(tabbable => {
        tabbable.forEach(element => {
            if (element._tabindex) {
                element.setAttribute('tabindex', element._tabindex);
            }
            element.setAttribute('tabindex', '0');
        });
    });

    return trappedElements;
};

/**
 * Handles un trapping focus on low performance platforms without ally.js
 * @method untrapFocusForLowPerformancePlatforms
 * @private
 * @param {array} trappedElements
 */
const untrapFocusForLowPerformancePlatforms = trappedElements => {
    // Check if trapped elements exists
    if (!trappedElements.length) {
        return;
    }

    for (let i = 0; i < trappedElements.length; i++) {
        const element = trappedElements[i];
        if (element._tabindex) {
            element.setAttribute('tabindex', element._tabindex);
        } else {
            element.removeAttribute('tabindex');
        }
        if (element.tagName === 'SVG' || element.tagName === 'svg') {
            if (!element._focusableFalse) {
                element.removeAttribute('focusable');
            }
        }
    }
};

/**
 * Handles un trapping focus with ally.js
 * @method untrapFocus
 * @private
 * @param {function} trapFocusHandle
 * @param {function} maintainHiddenHandle
 */
const untrapFocus = (trapFocusHandle, maintainHiddenHandle) => {
    trapFocusHandle.disengage();
    maintainHiddenHandle.disengage();
};

/**
 * Handles trapping focus with ally.js and returns a function to disengage
 * @method trapFocus
 * @public
 * @param {array} filterElements
 * @returns {function}
 */
export const trapFocus = filterElements => {
    if (!filterElements) {
        return;
    }

    if (Array.isArray(filterElements)) {
        filterElements
            .filter(el => el)
            .forEach(el => {
                assert.type(
                    isElement(el) || el instanceof NodeList,
                    'Expected element to be an HTMLElement or NodeList'
                );
            });
    } else {
        assert.type(
            isElement(filterElements) || filterElements instanceof NodeList,
            'Expected element to be an HTMLElement or NodeList'
        );
    }

    const filterElementsArray = Array.isArray(filterElements)
        ? filterElements
        : [filterElements];

    if (isLowPerformancePlatform()) {
        // For platforms that are considered low performance with ally
        const trappedElements = trapFocusForLowPerformancePlatforms(
            filterElementsArray
        );

        return () => untrapFocusForLowPerformancePlatforms(trappedElements);
    } else {
        // Disable all unfiltered elements, ie tab focusable
        const trapFocusHandle = maintainDisabled({
            filter: filterElementsArray,
        });

        // Add aria-hidden to all unfiltered elements, ie screen readers
        const maintainHiddenHandle = maintainHidden({
            filter: filterElementsArray,
        });

        return () => untrapFocus(trapFocusHandle, maintainHiddenHandle);
    }
};

/**
 * @param {HTMLElement} element
 */
export const focusAndScrollElementIntoView = element => {
    element.focus();
    element.scrollIntoView({ block: 'nearest' });
};

/**
 * Finds the first focusable element within an element and focuses it
 * and scrolls into view
 * @method focusFirstFocusable
 * @public
 * @param {node} element
 * @param {boolean} includeContext
 * @returns {array}
 */
export const focusFirstFocusable = (element, includeContext = false) => {
    assert.type(
        isElement(element),
        'Expected the argument to be an HTMLElement.'
    );

    const focusableElements = queryFocusable({
        includeContext,
        context: element,
        strategy: 'quick',
    });

    if (focusableElements.length) {
        focusAndScrollElementIntoView(focusableElements[0]);
    }

    return focusableElements;
};

/**
 * @method removeInert
 * @private
 * @param {Object} maintainDisabledHandle
 */
const removeInert = maintainDisabledHandle => {
    maintainDisabledHandle.disengage();
    // TODO: Maintain hidden throws error about needing a filter even though docs say it can be null
    // maintainHiddenHandle.disengage();
};

/**
 * Disables elements within a given context and returns a function to disengage
 * @method setInert
 * @public
 * @param {element} context
 * @param {element} filter
 * @returns {function}
 */
export const setInertWithin = context => {
    const maintainDisabledHandle = maintainDisabled({ context });
    // TODO: Maintain hidden throws error about needing a filter even though docs say it can be null
    // const maintainHiddenHandle = maintainHidden({ context, filter: null });
    return () => removeInert(maintainDisabledHandle);
};

/**
 * Forces an update of the DOM to force [specifically,at the time of creation]
 * NVDA to re-catalog the DOM
 *
 * This was necessary to prevent NVDA from getting stuck *somewhere* after untrapping focus.
 */
export const forceNVDADomUpdate = () => {
    requestAnimationFrame(() => {
        const div = document.createElement('div');
        document.body.appendChild(div);
        requestAnimationFrame(() => {
            document.body.removeChild(div);
        });
    });
};
