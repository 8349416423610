import t from 'tcomb-form';
import _invert from 'lodash/invert';

/**
 * @type {Object}
 */
export const ANIMAL_TYPES_ENUM = {
    any: 'Any',
    dog: 'Dogs',
    cat: 'Cats',
    rabbit: 'Rabbits',
    'small-furry': 'Small & Furry',
    horse: 'Horses',
    bird: 'Birds',
    'scales-fins-other': 'Scales, Fins, & Other',
    barnyard: 'Barnyard',
};

/**
 * @type {Object}
 */
export const ANIMAL_TYPES_ID_TO_LABEL_MAP = {
    1: ANIMAL_TYPES_ENUM['small-furry'],
    2: ANIMAL_TYPES_ENUM.dog,
    3: ANIMAL_TYPES_ENUM.cat,
    4: ANIMAL_TYPES_ENUM.bird,
    6: ANIMAL_TYPES_ENUM['scales-fins-other'],
    7: ANIMAL_TYPES_ENUM.barnyard,
    8: ANIMAL_TYPES_ENUM.rabbit,
    9: ANIMAL_TYPES_ENUM.horse,
};

/**
 * @type {Object}
 */
export const ANIMAL_TYPES_LABEL_TO_ID_MAP = _invert(ANIMAL_TYPES_ID_TO_LABEL_MAP);

export default t.enums(ANIMAL_TYPES_ENUM);
